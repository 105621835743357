import request from "./index";
import { BASE_URL, TIME_OUT } from "./config";
const http = new request({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: "https://mdq.mdqglb.com/prod-api/",
  timeout: TIME_OUT,
  interceptors: {
    responseInterceptors: (res: any) => {
      if (res.status == 200) {
        return res.data;
      }
    },
  },
});
export default http;
