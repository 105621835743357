 const en = {
  // 菜单栏翻译
  menus: {
    home: "Home",
    about: "About",
    product: "Product",
    channel: "Channel",
    news: "News",
    service: "Service",
    login: "Login",
    regist: "Regist",
  },
  // 查询插件
  PriceInquiry: "PriceInquiry",
  TrackingNumber: "TrackingNumber",
  S: "S",
  R: "R",
  W: "W",
  V: "V",
  liquid:"liquid",
  electrification: "Electrification",
  generalCargo: "GeneralCargo",
  inquire: "Inquire",
  pstordr:"Original delivery area",
  aod:"Area of destination",
  tpw:"The parcel weighs",
  ipt:"input",
  long:"L",
  wide:"W",
  high:"W",
  detail:'Detail',
   //产品服务
   product:{
    ExportTrade:"ExportTrade",
    Iff:"International freight forwarders",
    Cbe:"Cross-border electricity",
    more:"More"
  },
   //关于我们
   about:{
    tcwf:"The company was founded",
    WXY:"Warehouse in Xiyong Comprehensive Protection Zone",
    oedaw:"Own express delivery allocation warehouse",
    otw:"Outside the warehouse",
  },
   //关于我们view页面
   aboutView: {
    companyProfile:"companyProfile",
    cooperativePartner:"cooperativePartner",
    contactUs:"contactUs",
    LTD:"Chongqing Midoqi International Logistics Co. LTD",
    detil:`Chongqing mido's international logistics co., LTD., founded in 2015, after years of development, the company relies on advanced management concept, professional ability, good service level, rich experience in the industry and good commercial reputation, set up the excellence, integrity, honest enterprise image and the industry reputation, has been an excellent cross-border in chongqing electric business integrated logistics service provider.

    The company is headquartered in Xiyong, Chongqing, close to the western (Chongqing) Science City, Chongqing University Town, Xiyong comprehensive Free Trade Zone and Chongqing railway port, customers all over the country, in the main domestic port cities and the United States, Europe and other countries and regions have cooperation institutions, warehousing and transshipment centers and overseas warehouse partners. Relying on rich industry resources, years of logistics service experience and perfect smart logistics system, the company focuses on creating the integrated development business model of "cross-border logistics + cross-border e-commerce", and builds a comprehensive logistics system including local warehousing, European and American air transportation, European iron transportation, European card transportation, international packet transportation and international express delivery. To provide customers with comprehensive cross-border logistics solutions and "one-stop" integrated logistics services.
    
    I. Cross-border logistics business
    
    1. Self-owned storage: Our company has 26,000 square meters of warehouses in Xiyong Comprehensive Bonded Zone, 15,000 square meters of warehouses outside the zone, and 5,000 square meters of self-built express intelligent distribution warehouse, which can meet different types of storage needs and provide customers with goods storage and distribution services with lower storage costs.
    
    2. European and American Airlines: Our company has a long-term cooperation agreement with domestic mainstream airlines. We can provide customers with international air transport services to Europe and the United States.
    
    3. European Railway Group: It has a long-term cooperation agreement with China-Europe freight train (Chongqing-Xinzhou-Europe), guaranteed first-class shipping space, and has the advantages of year-round stable shipping space, convenient operation, stable timeliness and affordable price.
    
    4. European Capai: Our company has established long-term cooperative relations with major international fleets in China and Europe. With the advantages of stable vehicle sources and affordable prices, we can undertake the transportation of special goods such as electrified and liquid, and provide customers with door-to-warehouse transportation services from all parts of China to Europe.
    
    5. International package: Our company has established special line products covering Europe, the United States, Canada, Brazil and other countries and regions. Can undertake electrified, liquid, cosmetics and other special goods. The whole year, no price fluctuation.
    
    6. International express: Our company has established a long-term cooperative relationship with three major international express companies, which has good channel advantages and can provide customers with international express services with global access.
    
    7. Double clearance service: Our company has its own customs clearance team in Chongqing and overseas cooperation institutions with many years of customs clearance experience, which can provide customers with 0110, 9610, 9710, 9810 customs clearance services in China and self-tax, tax-inclusive, deferred and other forms of overseas customs clearance services.
    
    Ii. Cross-border e-commerce Park operation
    
    We as eBay's official authorized in chongqing only talent incubator optimization service, owned by chongqing bei cross-border electricity business school is responsible for the operation of cross-border electricity industrial park, west wing can provide professional personnel training, enterprise incubation, Shared location, supply chain finance, double selection of talents, in industrial and commercial registration, European finance and tax consulting, international logistics and so on comprehensive cross-border electricity business ecosystem services.
    
      `
  },
  //页脚
  foot:{
    Product:"Product",
    about:"About",
    Help:"Channel",
    new:"new",
    '24-hour': "24-hour service hotline",
  },
  //联系我们
  contact:{
    comm:"Chongqing Midoqi International Logistics Co",
    Cphone:"The phone company",
    email:"email",
    address:"address",
    addressTxt:"5 Xiyuan South Street, Xiyong Street, High-tech Zone, Chongqing"
  }
};

export default en