import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "",
        meta: { title: "首页" },
        component: () => import("../views/home/home.vue"),
      },
      {
        path: "product",
        meta: { title: "产品服务" },
        component: () => import("../views/product/product.vue"),
        children: [
          {
            path: "productDetil",
            meta: { title: "产品详情" },
            name: "productDetil",
            component: () => import("../views/productDetil/productDetil.vue"),
          },
        ],
      },
      {
        path: "channelView",
        meta: { title: "渠道服务" },
        component: () => import("../views/channelView/channelView.vue"),
        children: [
          {
            path: "channeDetil",
            meta: { title: "渠道详情" },
            name: "channeDetil",
            component: () => import("../views/channeDetil/channeDetil.vue"),
          },
        ],
      },
      {
        path: "inpPrice",
        name:'inpPrice',
        meta: { title: "价格查询" },
        component: () => import("../views/inpPrice/inpPrice.vue"),
        children: [],
      },
      {
        path: "waybill",
        meta: { title: "运单查询" },
        component: () => import("../views/waybill/waybill.vue"),
        children: [],
      },
      {
        path: "pressCenter",
        meta: { title: "新闻中心" },
        component: () => import("../views/pressCenter/pressCenter.vue"),
        children: [
          {
            path: "pressCenterDetil",
            meta: { title: "新闻详情" },
            name: "pressCenterDetil",
            component: () =>
              import("../views/pressCenterDetil/pressCenterDetil.vue"),
          },
        ],
      },
      {
        path: "about",
        meta: { title: "关于我们" },
        component: () => import("../views/aboutView/aboutView.vue"),
        children: [],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory("/"),
  routes,
});

export default router;
