import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "home"
};
export function render(_ctx, _cache) {
  const _component_nav_bar = _resolveComponent("nav-bar");

  const _component_router_view = _resolveComponent("router-view");

  const _component_footers = _resolveComponent("footers");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_nav_bar), _createVNode(_component_router_view), _createVNode(_component_footers)]);
}