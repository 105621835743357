import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "@vant/touch-emulator";
import { scrollBoard } from '@jiaminghi/data-view';
import "vant/lib/index.css";
import "amfe-flexible";
import "@/utils/rem.js";
import vuei18n from "@/locales/index";
import i18n from "@/locales/index";
import http from "./utils/request/request";
import ElementPlus from 'element-plus'
import "element-plus/theme-chalk/src/message.scss"
const app = createApp(App);



// 注册全局变量
app.config.globalProperties.t = i18n.global.t;
app.config.globalProperties.$http = http;
app.use(scrollBoard).use(ElementPlus).use(vuei18n).use(Vant).use(store).use(router).mount("#app");
