const zh = {
  // 菜单栏翻译
  menus: {
    "/": "首页",
    home: "首页",
    about: "关于我们",
    product: "产品服务",
    channel: "优势渠道",
    news: "新闻中心",
    service: "服务支持",
    login: "登录",
    regist: "注册",
  },
  // 查询插件
  PriceInquiry: "价格查询",
  TrackingNumber: "追踪单号",
  china: "中国",
  S: "寄",
  R: "收",
  W: "重",
  V: "体",
  liquid: "液体",
  electrification: "带电",
  generalCargo: "普货",
  inquire: "查询",
  pstordr: "请选择原寄递地区",
  aod: "请选择目的地地区",
  tpw: "请输入包裹重量",
  ipt: "请输入",
  long: "长",
  wide: "宽",
  high: "高",
  // 详情
  detail: "详情",
  //产品服务
  product: {
    ExportTrade: "出口贸易",
    Iff: "国际货代",
    Cbe: "跨境电商",
    more: "查看更多",
  },

  //关于我们
  about: {
    tcwf: "公司创建于",
    WXY: "西永综保区区内仓库",
    oedaw: "自有快递分拨仓",
    otw: "区外仓库",
  },
  //关于我们view页面
  aboutView: {
    companyProfile: "公司简介",
    cooperativePartner: "合作伙伴",
    contactUs: "联系我们",
    LTD: "重庆米多奇国际物流有限公司",
    detil:
      "重庆米多奇国际物流有限公司成立于2015年，经多年发展，公司凭借先进的管理理念、专业的业务能力、优良的服务水平、丰富的行业经验和良好的商业信誉，树立了精益求精、诚信厚道的企业形象和业界口碑，已成为重庆地区优秀的跨境电商综合物流服务商。" +
      "公司总部位于重庆西永，紧靠西部（重庆）科学城、重庆大学城、西永综合保税区和重庆铁路口岸，客户遍及全国各地，在国内主要口岸城市及美国、欧洲等国家和地区均有合作机构、仓储转运中心和海外仓合作伙伴。依托丰富的行业资源、多年的物流服务经验和完善的智慧物流系统，着重打造“跨境物流+跨境电商”融合发展商业模式，构建了集本地仓储、欧美空派、欧洲铁派、欧洲卡派、国际小包、国际快递等综合物流体系，为客户提供全面的跨境物流解决方案和“一站式”综合物流服务" +
      "一、跨境物流业务 1.自有仓储：我司在西永综合保税区内有仓库26000平米，区外仓库15000平米，自建快递智能分拨仓5000平米，可满足不同类型的仓储需求，能为客户提供较低仓储成本的货物存储与集散分拨服务。2.欧美空派：我司与国内主流航空公司有长期合作协议，全年自主包板，舱位稳定，能为客户提供至欧洲、美国的国际空运到仓服务。" +
      "3.欧洲铁派：与中欧班列（渝新欧）有长期合作协议，一手舱位保障，具备全年舱位稳定、操作便捷、时效稳定、价格实惠等优势。4.欧洲卡派：我司与中欧主要国际车队均建立长期合作关系，具有车源稳定、价格实惠等优势，可承接带电、液体等特殊货物运输，能为客户提供从中国各地至欧洲各地的门到仓运输服务。4.欧洲卡派：我司与中欧主要国际车队均建立长期合作关系，具有车源稳定、价格实惠等优势，可承接带电、液体等特殊货物运输，能为客户提供从中国各地至欧洲各地的门到仓运输服务。" +
      "5.国际小包：我司自建覆盖欧洲、美国、加拿大、巴西等国家及地区的小包专线产品。可承接带电、液体、化妆品等特殊货物。全年一口价、无价格波动。6.国际快递：我司与三大国际快递公司建立了长期合作关系，具有较好渠道优势，能为客户提供通达全球的国际快递服务。7.双清服务：我司拥有重庆自有清关团队及海外多年清关经验的合作机构，能为客户提供中国境内0110、9610、9710、9810的报关服务及自税、包税、递延等多种形态的境外清关服务。" +
      "二、跨境电商园区运营我司作为eBay官方授权的重庆地区唯一人才孵化优选服务商，旗下渝贝跨境电商学院负责运营的西永跨境电商产业园，可提供专业人才培养、企业孵化、共享工位、供应链金融、人才双选、入驻企业工商注册、欧洲财税咨询、国际物流等跨境电商生态链的综合服务。",
  },
  //页脚
  foot: {
    Product: "产品服务",
    about: "关于我们",
    Help: "优势渠道",
    new: "新闻中心",
    "24-hour": "24小时服务热线：",
  },
  //联系我们
  contact: {
    comm: "重庆米多奇国际物流公司",
    Cphone: "公司电话",
    email: "邮箱",
    address: "公司地址",
    addressTxt: "重庆市沙坪坝区西园南街5号R26-2栋8楼",
  },
};
export default zh;
